import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import { create as createModal, useModal } from "@ebay/nice-modal-react";
import { AxiosError } from "axios";
import { CrossFadeSwitch } from "components/cross-fade-switch";
import { Form } from "components/input/form";
import { LoginError } from "errors/login-error";
import {
  StateMachineSteps,
  useMultiStepWizard,
} from "hooks/use-multi-step-wizard";
import { Bugsnag } from "lib/bugsnag";
import { FormError } from "patterns/form-error";
import { useForm } from "react-hook-form";
import { apiActions } from "screens/login/hooks/use-login-wizard";
import { DownloadAuthenticatorApp } from "screens/profile/components/download-authenticator-app";
import { ScanQrCode } from "screens/profile/components/scan-qr-code";
import { Steps } from "screens/profile/components/steps";
import { VerifyPassword } from "screens/profile/components/verify-password";
import { useProfile } from "screens/profile/hooks/use-profile";

type State = {
  password_confirmation: string;
  code: string;
  enrolment_id: string;
};

export const MultiFactorEnrolment = createModal<{
  initialStepKey?: string;
  onEnrolmentComplete?: () => void;
}>(({ initialStepKey, onEnrolmentComplete }) => {
  const modal = useModal();
  const {
    createMultiFactorEnrolment,
    enableMultiFactorEnrolment,
    error: profileError,
    multiFactorEnrolment,
    getUser,
  } = useProfile();
  const toast = useToast();

  const steps = {
    downloadApp: {
      Component: DownloadAuthenticatorApp,
      title: "Download App",
      onSubmit: async ({ state }) => {
        if (!multiFactorEnrolment) {
          await createMultiFactorEnrolment();
        }
        return state;
      },
      getNextStep: () =>
        multiFactorEnrolment && !multiFactorEnrolment.is_enabled
          ? "verifyPassword"
          : "scanQRCode",
    },
    scanQRCode: {
      Component: ScanQrCode,
      title: "Scan QR Code",
      onSubmit: async ({ data, state }) => {
        const { isMultiFactorVerified } = await apiActions.verifyMultiFactor({
          data: { ...data, enrolment_id: multiFactorEnrolment?.id },
          state: state as any,
        });

        if (isMultiFactorVerified) {
          getUser();
        }
      },
      getNextStep: () => "verifyPassword",
    },
    verifyPassword: {
      Component: VerifyPassword,
      title: "Verify Password",
      onSubmit: async ({
        data,
      }: {
        data: { password_confirmation: string };
      }) => {
        const result = await enableMultiFactorEnrolment({
          id: multiFactorEnrolment?.id as string,
          password_confirmation: data.password_confirmation as string,
        });

        if (!result) return;

        await getUser();

        toast({
          title: "Multi-factor authentication enabled",
          description:
            "All app sessions have been cleared and you will need to login again.",
          position: "top",
          status: "success",
          size: "xl",
          duration: 6000,
        });

        if (result) {
          modal.hide().then(() => modal.remove());
          onEnrolmentComplete?.();
        }
      },
      getNextStep: () => null,
    },
  } as const satisfies StateMachineSteps<State>;

  const wizard = useMultiStepWizard({
    steps,
    initialState: {} as State,
    initialStepKey: initialStepKey as keyof typeof steps,
    onError: function handleError(
      e: Error,
      { setError, state }
    ): { state: State; error: string | undefined } {
      let error;
      if (e instanceof LoginError) {
        error = e.message;
      } else if (
        e instanceof AxiosError &&
        e?.response?.data?.exception === "ValidationException"
      ) {
        error = e?.response?.data?.message;
      } else {
        Bugsnag.notify(e);
        console.error(e);
        error = "An unknown error occurred";
      }

      setError(error);

      return { state, error };
    },
  });
  const { currentStep, error, stepHistory } = wizard;

  const form = useForm<State>({
    defaultValues: {
      enrolment_id: multiFactorEnrolment?.id,
      password_confirmation: "",
      code: "",
    },
  });

  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Modal
      isOpen={modal.visible}
      onClose={modal.hide}
      onCloseComplete={modal.remove}
      size={["full", "lg"]}
      isCentered={useBreakpointValue({ base: false, md: false, lg: true })}
    >
      <Form form={form} onSubmit={currentStep.onSubmit}>
        <ModalOverlay bg={"blackAlpha.500"} />
        <ModalContent mt={0} mb={0}>
          <ModalHeader w={["90%", "auto"]}>
            Multi-factor authentication setup
          </ModalHeader>
          <ModalCloseButton size={"lg"} />
          <ModalBody>
            {initialStepKey !== "verifyPassword" && !isMobile ? (
              <Steps
                steps={steps}
                currentStep={currentStep.key}
                stepHistory={stepHistory}
              />
            ) : null}
            <FormError error={error || profileError} />
            <CrossFadeSwitch childKey={currentStep.key}>
              {currentStep ? <currentStep.Component {...wizard} /> : null}
            </CrossFadeSwitch>
          </ModalBody>
        </ModalContent>
      </Form>
    </Modal>
  );
});
