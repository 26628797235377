import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import {
  create as niceModalCreate,
  show as niceModalShow,
  useModal,
} from "@ebay/nice-modal-react";
import { useQueryClient } from "@tanstack/react-query";
import { Form } from "components/input/form";
import { FormField } from "components/input/form-field";
import { axios } from "lib/axios";
import { Select } from "lib/chakra-react-select";
import { useForm } from "react-hook-form";
import { HiQuestionMarkCircle } from "react-icons/hi";
import { showTokenCreatedDialog } from "screens/profile/components/token-created-dialog";
import { AuthServiceAppId } from "types/auth-service-app-id";

interface CreateTokenFormData {
  name: string;
  scopes: { value: string; label: string };
  rex_application_id: { value: AuthServiceAppId; label: string };
  expires_in_days: { value: number | null; label: string };
  integrator_code: string;
}

interface CreateTokenResponse {
  result: {
    id: string;
    token: string;
    integrator: {
      name: string;
      code: string;
    };
  };
}

const APP_OPTIONS = [
  { value: AuthServiceAppId.REX_CRM, label: "Rex CRM" },
  { value: AuthServiceAppId.REX_PM, label: "Rex PM" },
];

const EXPIRY_OPTIONS = [
  { value: 30, label: "30 days" },
  { value: 60, label: "60 days" },
  { value: 90, label: "90 days" },
  { value: 365, label: "1 year" },
  { value: null, label: "Never" },
];

export const showCreateTokenDialog = () => {
  return niceModalShow(CreateTokenDialog);
};

export const CreateTokenDialog = niceModalCreate(() => {
  const modal = useModal();
  const toast = useToast();
  const queryClient = useQueryClient();
  const form = useForm<CreateTokenFormData>({
    defaultValues: {
      rex_application_id: APP_OPTIONS[0],
      expires_in_days: EXPIRY_OPTIONS[0],
    },
  });

  const handleClose = () => {
    if (form.formState.isSubmitting) return;
    modal.hide();
    modal.reject();
    form.reset();
  };

  const handleSubmit = async (data: CreateTokenFormData) => {
    try {
      const response = await axios.post<CreateTokenResponse>(
        "user/integration-access-tokens",
        {
          name: data.name,
          scopes: ["full"],
          rex_application_id: data.rex_application_id.value,
          expires_in_days: data.expires_in_days?.value ?? null,
          integrator_code: data.integrator_code,
        }
      );

      await queryClient.invalidateQueries({ queryKey: ["integration-tokens"] });

      // Close this dialog first
      modal.hide();
      form.reset();

      // Then show the token in a new dialog
      await showTokenCreatedDialog({ token: response.data.result.token });

      // Finally, resolve this dialog
      modal.resolve();
    } catch (error: any) {
      console.error(error);
      toast({
        position: "top-right",
        status: "error",
        size: "xl",
        title: "Failed to create token",
        description: error.response?.data?.message || "An error occurred",
      });
    }
  };

  return (
    <Modal isOpen={modal.visible} onClose={handleClose} size={"xl"}>
      <ModalOverlay />
      <ModalContent>
        <Form form={form} onSubmit={handleSubmit}>
          <ModalHeader>Create Integration Token</ModalHeader>
          <ModalCloseButton disabled={form.formState.isSubmitting} />

          <ModalBody>
            <Stack spacing={4}>
              <FormField
                name={"name"}
                label={"Token Name"}
                Input={Input}
                required
                minLength={1}
                maxLength={255}
              />
              <FormField
                name={"integrator_code"}
                label={
                  <Flex align={"center"} gap={1}>
                    Integrator Code
                    <Tooltip
                      label={
                        "A unique reference code Rex assigns to each integrator. This identifies which integration this token is for."
                      }
                      hasArrow
                    >
                      <Box as={"span"} color={"gray.500"}>
                        <HiQuestionMarkCircle size={16} />
                      </Box>
                    </Tooltip>
                  </Flex>
                }
                Input={Input}
                required
              />
              <FormField
                name={"rex_application_id"}
                label={"Application"}
                Input={Select}
                inputProps={{
                  options: APP_OPTIONS,
                }}
                required
              />

              <FormField
                name={"expires_in_days"}
                label={"Expires In"}
                Input={Select}
                inputProps={{
                  options: EXPIRY_OPTIONS,
                  defaultValue: EXPIRY_OPTIONS[0],
                }}
                required
              />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              variant={"ghost"}
              mr={3}
              onClick={handleClose}
              isDisabled={form.formState.isSubmitting}
            >
              Cancel
            </Button>
            <Button type={"submit"} isLoading={form.formState.isSubmitting}>
              Create Token
            </Button>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  );
});
