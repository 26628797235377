import { Button, HStack, Stack } from "@chakra-ui/react";
import { Form } from "components/input/form";
import { FormField } from "components/input/form-field";
import { useApp } from "hooks/use-app";
import { Select } from "lib/chakra-react-select";
import { useForm } from "react-hook-form";
import { CurrentEmail } from "screens/login/components/current-email";
import { LoginWizard } from "screens/login/hooks/use-login-wizard";
import { orderBy } from "utils/array";
import { Link } from "wouter";

export function SelectAccountForm(wizard: LoginWizard) {
  const form = useForm<{
    account: { value: string; label: string; origin?: string };
  }>();
  const { app } = useApp();

  const {
    currentStep: { onSubmit },
    state: { accessibleAccounts },
    isLoading,
  } = wizard;

  return (
    <Form
      form={form}
      onSubmit={(d) =>
        onSubmit({ account_id: d.account.value, origin: d.account.origin })
      }
    >
      <Stack spacing={"6"}>
        <CurrentEmail {...wizard} />
        {accessibleAccounts?.length > 0 ? (
          <Stack spacing={"5"}>
            <FormField
              name={"account"}
              label={"Select account"}
              Input={Select as any}
              inputProps={{
                captureMenuScroll: false,
                options: orderBy(
                  accessibleAccounts?.map?.((a) => ({
                    value: a.account_id,
                    label: a.account_name,
                    origin: a.origin,
                  })) || [],
                  "label"
                ),
              }}
              required
            />
          </Stack>
        ) : null}
        <HStack justify={"space-between"} justifySelf={"flex-end"}>
          {!["rex_pm_inspections_app", "rex_crm_mobile"].includes(app.id) ? (
            <Link to={"/profile"}>
              <Button
                as={"a"}
                alignSelf={"flex-end"}
                variant={"outline"}
                target={"_blank"}
              >
                Edit Profile
              </Button>
            </Link>
          ) : null}
          {accessibleAccounts?.length ? (
            <Button
              alignSelf={"flex-end"}
              variant={"solid"}
              type={"submit"}
              isLoading={isLoading}
            >
              Log in
            </Button>
          ) : null}
        </HStack>
      </Stack>
    </Form>
  );
}
