import {
  Button,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import logoAuthy from "assets/authy-logo.svg";
import logoGoogleAuthenticator from "assets/google-authenticator-logo.svg";
import { useMultiStepWizard } from "hooks/use-multi-step-wizard";
import { useProfile } from "screens/profile/hooks/use-profile";

function RecommendedApp({ image, url }: { image: string; url: string }) {
  return (
    <Flex
      p={"6"}
      as={"a"}
      href={url}
      target={"_blank"}
      rel={"noopener"}
      bg={"white"}
      borderWidth={"1px"}
      align={"center"}
      justify={"center"}
      borderRadius={"xl"}
      transition={"background 100ms ease-out"}
      shadow={"sm"}
      borderColor={"slate.200"}
      cursor={"pointer"}
      _hover={{ bg: "brand.50" }}
    >
      <Image src={image} w={"100%"} />
    </Flex>
  );
}

export function DownloadAuthenticatorApp({
  isLoading,
}: {
  isLoading: boolean;
  goToPreviousStep: ReturnType<typeof useMultiStepWizard>["goToPreviousStep"];
}) {
  const { user, getUser } = useProfile();

  if (!user) {
    getUser();
  }

  return (
    <>
      <VStack align={"stretch"} spacing={"6"}>
        <Text>
          Start by installing an authenticator app on your phone. This app will
          generate unique, time-sensitive codes that will be required to log in.
        </Text>
        <VStack spacing={"4"}>
          <Heading size={"md"}>Recommended apps</Heading>
          <SimpleGrid columns={2} spacing={"4"} bg={"gray.100"} p={"4"}>
            <RecommendedApp
              image={logoGoogleAuthenticator}
              url={
                "https://apps.apple.com/au/app/google-authenticator/id388497605"
              }
            />
            <RecommendedApp image={logoAuthy} url={"https://authy.com/"} />
          </SimpleGrid>
        </VStack>
      </VStack>
      <Flex justify={"flex-end"} py={"8"}>
        <Button type={"submit"} disabled={!user} isLoading={isLoading}>
          I&apos;ve downloaded an app
        </Button>
      </Flex>
    </>
  );
}
